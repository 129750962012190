import Axios from '@/http'

export default {
  /* 内部加入会议 */
  videoMeetingIn(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/videoMeetingIn', { uniqueId }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 获取会议详情 */
  selectByUniqueId(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/selectByUniqueId', { uniqueId })
        .then((res) => {
          return resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /* 视频会议成员-正在房间内的 */
  roomMemberFindAll(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/member/roomMemberFindAll', { uniqueId })
        .then((res) => {
          return resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /* 需要打开监控的设备 */
  getMonitorDevice(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/getMonitorDevice', { uniqueId })
        .then((res) => {
          return resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /* 创建视频会议新标签页获取mq连接信息校验码 */
  validCodeCreate(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/validCode/create', { uniqueId }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 发起禁言or取消禁言 */
  videoMeetingMuted(params) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/muted', params).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 查询禁言状态(1禁言、2未禁言) */
  videoMeetingGetMuted(uniqueId) {
    return new Promise((resolve, reject) => {
      Axios.post('videoMeeting/getMuted', { uniqueId }).then((res) => {
        return resolve(res)
      })
    })
  },
  /* 手动邀请设备 */
  deviceVideoMeetingInvite(params) {
    return new Promise((resolve, reject) => {
      Axios.post('test/deviceVideoMeetingInvite', params).then((res) => {
        return resolve(res)
      })
    })
  }
}
