<template>
  <div id="app">
    <div class="p_20 h_100vh" style="box-sizing: border-box">
      <el-tabs
        v-if="menuList.length > 1"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          :key="index"
          v-for="(item, index) in menuList"
          :label="item.label"
          :name="item.name"
        ></el-tab-pane>
      </el-tabs>
      <div
        :style="{ height: menuList.length > 1 ? 'calc(100% - 80px)' : '100%' }"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="less">
/*@import "styles/main.css";*/
/*@import 'styles/basics.less';*/
.operator-wrapper {
  justify-content: space-between;
  /*padding: 12px 20px;*/
  height: 100%;
  .operator-menu-box {
  }
  .operator-content {
    width: 100%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
<script type="text/ecmascript-6">
  const { menu } = require(`@/version/${process.env.VUE_APP_BUILD_VERION}.js`)
  export default {
  data () {
    return {
      activeName: null,
      menuList: menu
    }
  },
  components: {
  },
  methods: {
    handleClick () {
      this.$router.push({ path: `/${this.activeName}` })
    }
  },
  mounted () {
    let userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.$store.commit('setUserInfo', userInfo)
    }
    let permissionIdList = window.sessionStorage.getItem('permissionIdList')
    if (permissionIdList.length !== 0) {
      permissionIdList = JSON.parse(permissionIdList)
      this.$nextTick(() => {
        const dataList = []
        for (let i = 0; i < this.menuList.length; i++) {
          const item = this.menuList[i]
          if (permissionIdList.find(v => v === item.permission)) {
            dataList.push({ ...item })
          }
        }
        this.$nextTick(() => {
          console.log('dataList', dataList)
          this.menuList = dataList
          if (this.menuList.length) {
            this.activeName = this.menuList[0].name
            this.$router.push({ path: `${this.menuList[0].path}` })
          }
        })
      })
    }
  }
}
</script>
