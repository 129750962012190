import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
import VueCropper from 'vue-cropper'
import routes from './router'
import ElementUI from 'element-ui'
import api from './api'
import inputCheck from '@utils/inputCheck.js'
import BaseUrlConfig from '@/http/config.js'
import VueI18n from 'vue-i18n'
import rsa from '@utils/rsa'
Vue.use(ElementUI)
Vue.use(api)
Vue.use(VueCropper)
Vue.use(inputCheck)
const cloneDeep = window.customPublicMethod.cloneDeep
for (const componentsName in window.commonComponents) {
  Vue.component(
    componentsName,
    cloneDeep(window.commonComponents[componentsName])
  )
}
// 多语言处理
Vue.use(VueI18n)
Vue.use(rsa)
Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// 文件路径 是否有子级 校验
const req = require.context('@/assets/svg', true, /\.svg$/)
const requireAll = (requireContent) => requireContent.keys().map(requireContent)
requireAll(req)

let router = null
let instance = null

Vue.directive('permission', {
  inserted: (el, binding) => {
    const operation = binding.value
    let permissionIdList = window.sessionStorage.getItem('permissionIdList')
    console.log(permissionIdList, operation)
    if (permissionIdList.length !== 0 && operation) {
      permissionIdList = JSON.parse(permissionIdList)
      if (permissionIdList.findIndex((v) => v === operation) === -1) {
        el.parentNode.removeChild(el)
      }
    }
  }
})
Vue.directive('delTabIndex', {
  bind(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    el.__vueSetTimeoutIndex__ = setTimeout(() => {
      // 清除当前tabIndex
      el.removeAttribute('tabindex')
      clearTimeout(el.__vueSetTimeoutIndex__)
    }, 0)
  },
  unbind(el) {
    clearTimeout(el.__vueSetTimeoutIndex__)
  }
})
Vue.directive('none', {
  inserted: (el) => {
    if (el.children.length === 0) {
      el.parentNode.removeChild(el)
    }
  }
})

function render(props = {}) {
  const nginxUrl = '/alert'
  const {
    container,
    store: mainStore,
    messages,
    httpConfig,
    deleteConfirmationBox
  } = props
  BaseUrlConfig.initHttpConfig(httpConfig)
  mainStore.commit('globalLoadingFn', false)
  Vue.prototype.mainStore = Vue.observable(mainStore)
  Vue.prototype.$deleteConfirmationBox = deleteConfirmationBox
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? nginxUrl : '/',
    mode: 'hash',
    routes
  })
  const i18n = new VueI18n({
    // locale: JSON.parse(window.sessionStorage.getItem('language')) === 'Chinese' ? 'zh' : 'en',
    locale: 'zh',
    messages,
    silentTranslationWarn: true
  })
  instance = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app')
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

// --------- 生命周期函数------------ //
export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}

export async function mount(props) {
  console.log('[vue] props from main framework', props)
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  router = null
  instance = null
}
