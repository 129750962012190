/* 路由 */
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/policeRecord'
  },
  {
    path: '/policeRecord',
    name: 'policeRecord',
    meta: {
      label: '警情记录'
    },
    component: () => import('@/views/policeRecord/policeRecord')
  }
]
/* 侧边导航 */
const menu = [
  {
    label: '警情记录',
    permission: 'alert_policeRecord',
    name: 'policeRecord',
    path: '/policeRecord'
  }
]

const componentsContent = {
  policeRecord: 'policeRecord_pipingGas',
  /* <!-- 接警内容（下发就是编辑） --> */
  alarmReceivingContent: 'alarmReceivingContent_pipingGas',
  /* 地图警情点 */
  policeMapManagement: 'policeMapManagement_pipingGas',
  /* 现场通话 */
  policeSceneConversation: 'policeSceneConversation_pipingGas'
}

const componentsStyle = {}

module.exports = {
  routes,
  menu,
  componentsContent,
  componentsStyle
}
