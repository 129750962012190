import Axios from '@/http'

export default {
  businessSystemNameLogo() {
    return new Promise((resolve, reject) => {
      Axios.post('common/businessSystemNameLogo').then((res) => {
        return resolve(res)
      })
    })
  }
}
