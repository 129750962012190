import Axios from '@/http'
export default {
  exportDailyCheckReport(url) {
    return new Promise((resolve, reject) => {
      Axios.get(url, {
        responseType: 'blob'
      }).then((res) => {
        resolve(res)
      })
    })
  }
}
