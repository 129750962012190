import http from '@/http'

export default {
  redirectFn(url) {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          responseType: 'blob',
          oosUpload: true,
          ContentType: ''
        })
        .then((res) => {
          resolve(res)
        })
    })
  },
  pcFileNumCal(caseId) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/pc/fileNumCal', { caseId }).then((res) => {
        resolve(res)
      })
    })
  },
  userFileNumCal(deviceUserId) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/userFileNumCal', { deviceUserId }).then((res) => {
        resolve(res)
      })
    })
  },
  deviceUserListFindAll(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/deviceUserList/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 仅查询有数据的成员
  intercomDeviceUserListFindAll(params) {
    return new Promise((resolve, reject) => {
      http
        .post('/mediaFile/intercom/deviceUserList/findAll', params)
        .then((res) => {
          resolve(res)
        })
    })
  },
  orgGroupListFindAll(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/orgGroupList/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 仅查询有数据的群组
  intercomOrgGroupListFindAll(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/intercom/groupInfo/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  orgGroupIntercomFindAll(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/orgGroup/intercom/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  orgGroupIntercomPage(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/orgGroup/intercom/page', params).then((res) => {
        resolve(res)
      })
    })
  },
  deviceUserGroupIntercomPage(params) {
    return new Promise((resolve, reject) => {
      http
        .post('/mediaFile/deviceUserGroup/intercom/page', params)
        .then((res) => {
          resolve(res)
        })
    })
  },
  deviceUserInGroup(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/deviceUserInGroup', params).then((res) => {
        resolve(res)
      })
    })
  },
  remarkFindAll(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/remark/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  saveRemark(params) {
    return new Promise((resolve, reject) => {
      http
        .post('/mediaFile/saveRemark', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  },
  // 备注/打点批量删除
  deleteBatchRemark(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/remark/deleteBatch', params).then((res) => {
        resolve(res)
      })
    })
  },
  deviceMediaFileTarget(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/deviceMediaFileTarget', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 按日期分组查找
  groupByDate(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/groupByDate', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 查找Point点
  pointFindAll(params) {
    return new Promise((resolve, reject) => {
      http.post('/mediaFile/point/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  // 保存Point点
  savePoint(params) {
    return new Promise((resolve, reject) => {
      http
        .post('/mediaFile/savePoint', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  }
}
